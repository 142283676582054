import css from "./ListingCard.module.css";

const ListingCardDate = ({ text }) => {
  return (
    <div className={css.metaItem}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_280_1030"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="18"
        >
          <rect
            width="18"
            height="18"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_280_1030)">
          <path
            d="M6.75 12.3752C6.225 12.3752 5.78125 12.1939 5.41875 11.8314C5.05625 11.4689 4.875 11.0252 4.875 10.5002C4.875 9.97518 5.05625 9.53143 5.41875 9.16893C5.78125 8.80643 6.225 8.62518 6.75 8.62518C7.275 8.62518 7.71875 8.80643 8.08125 9.16893C8.44375 9.53143 8.625 9.97518 8.625 10.5002C8.625 11.0252 8.44375 11.4689 8.08125 11.8314C7.71875 12.1939 7.275 12.3752 6.75 12.3752ZM3.75 16.5002C3.3375 16.5002 2.98438 16.3533 2.69063 16.0596C2.39688 15.7658 2.25 15.4127 2.25 15.0002V4.50018C2.25 4.08768 2.39688 3.73456 2.69063 3.44081C2.98438 3.14706 3.3375 3.00018 3.75 3.00018H4.5V1.50018H6V3.00018H12V1.50018H13.5V3.00018H14.25C14.6625 3.00018 15.0156 3.14706 15.3094 3.44081C15.6031 3.73456 15.75 4.08768 15.75 4.50018V15.0002C15.75 15.4127 15.6031 15.7658 15.3094 16.0596C15.0156 16.3533 14.6625 16.5002 14.25 16.5002H3.75ZM3.75 15.0002H14.25V7.50018H3.75V15.0002ZM3.75 6.00018H14.25V4.50018H3.75V6.00018Z"
            fill="#4B4B4F"
          />
        </g>
      </svg>
      <div>{text}</div>
    </div>
  );
}

export default ListingCardDate;
